























































































































































































































































































































@import "@/style/mixins";

.around-me {
  height: 100%;
  display: flex;
  flex-flow: column;

  .around-me-container {
    height: 100%;
    min-height: 0;
    overflow: hidden;

    .title {
      display: none;
      @include title-secondary-style();
    }

    .tab {
      width: 240px;
      height: 100%;
      background-color: white;
      overflow: auto;
      box-shadow: 0 5px 5px 5px rgba(rgb(31, 31, 31), 0.03);

      .filter-title {
        padding: 40px 40px 0 40px;
        @include title-secondary-style();
      }

      .filter-container {
        padding: 25px 35px 25px 40px;

        .filter-content {
          padding: 15px 0;

          .filter-name {
            @include body();
            padding-right: 15px;
          }

          .checkbox {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            border-radius: 4px;
            border: solid 1.5px $secondary;
            background-color: rgba($background-default, 0.5);
            position: relative;

            &.aqua {
              border-color: $place-category-aqua;
            }

            &.pink {
              border-color: $place-category-pink;
            }

            &.cyan {
              border-color: $place-category-cyan;
            }

            &.blue {
              border-color: $place-category-blue;
            }

            &.orange {
              border-color: $place-category-orange;
            }

            &.red {
              border-color: $place-category-red;
            }

            &.blue {
              border-color: $place-category-blue;
            }

            &.magenta {
              border-color: $place-category-magenta;
            }

            &.residence {
              border-color: $primary;
            }

            &.skyBlue {
              border-color: $place-category-skyBlue;
            }

            &.yellowGreen {
              border-color: $place-category-yellowGreen;
            }

            &.forestGreen {
              border-color: $place-category-forestGreen;
            }

            &.gold {
              border-color: $place-category-gold;
            }

            &.deepPink {
              border-color: $place-category-deepPink;
            }

            &.darkMagenta {
              border-color: $place-category-darkMagenta;
            }

            &.darkOrange {
              border-color: $place-category-darkOrange;
            }

            &.darkRed {
              border-color: $place-category-darkRed;
            }

            .checked {
              position: absolute;
              bottom: 1px;
              left: 2px;
            }
          }
        }
      }
    }

    .content {
      min-width: 320px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: $md) {
      flex-flow: column;

      .title {
        width: 100%;
        height: 70px;
        text-align: left;
        display: inline-table;
        background-color: white;

        .title-container {
          height: 100%;
          padding: 0 30px;
        }
      }

      .tab {
        width: 100%;
        height: auto;

        .filter-title {
          display: none;
        }
      }
    }
  }
}
